import '../Styles/joblist.css'
import jobPostings from '../Jobs/JobPostings.json';
import React, {useState, useEffect} from 'react';

const JobList = () => {

  const sortedPostings = jobPostings.sort((a, b) => {
    if (a.job_title < b.job_title) return -1;
    if (a.job_title > b.job_title) return 1;
    return 0;
  });

  const renderJobPostings = (location) => {
    return jobPostings
      .filter((job) => job.location === location)
      .map((job) => (
        <a className="job-link" href={`/get-involved/jobs/${job.route}`} key={job.id}>
          <div className="job-item">
            <h3 className='job-title'>{job.job_title}</h3>
          </div>
        </a>
      ));
  };

  const [activeTab, setActiveTab] = useState('toronto');

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 580) {
        setActiveTab('toronto');
      } else {
        setActiveTab(null); // Remove active tab class
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check on component mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
    <div className="mobile-wrapper">
      <h2>Choose a location:</h2>
      <div className="mobile-tab-wrapper">
        <h2 className={`mobile-tab ${activeTab === 'toronto' ? 'active-tab' : ''}`} id="toronto-tab" onClick={() => setActiveTab('toronto')} >Toronto</h2>
        <h2 className={`mobile-tab ${activeTab === 'montreal' ? 'active-tab' : ''}`} id="montreal-tab"  onClick={() => setActiveTab('montreal')} >Montreal</h2>
      </div>
    </div>
    <div className='job-section'>
      <h2 className='location-title'>Toronto</h2>
      <div className={`job-wrapper ${activeTab === 'toronto' ? 'active-wrapper' : ''}`} id="toronto-jobs">{renderJobPostings('Toronto')}</div>

      <h2 className='location-title'>Montreal</h2>
      <div className={`job-wrapper ${activeTab === 'montreal' ? 'active-wrapper' : ''}`} id="montreal-jobs">{renderJobPostings('Montreal')}</div>
    </div>

    </>
  );
};

export default JobList;