import './App.css';
import Homepage from './Homepage';
import About from './About'
import Events from './Events';
import CommunityPartners from './CommunityPartners';
import GetInvolved from './GetInvolved';
import Volunteer from './Volunteer';
import Careers from './Careers';
import Contact from './Contact';
import SalsaForSeniors from './SalsaForSeniors';
import JobPostTemplate from './Components/JobPostTemplate';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Homepage />}/>
      <Route path='/about' element={<About />}/>
      <Route path='/events' element={<Events />}/>
      <Route path='/community-partners' element={<CommunityPartners />}/>
      <Route path='/get-involved' element={<GetInvolved />}/>
      <Route path='/get-involved/jobs' element={<Careers />}/>
        <Route path={'get-involved/jobs/:route'} element={<JobPostTemplate />} />
      <Route path='/get-involved/volunteer' element={<Volunteer />}/>
      <Route path='/contact' element={<Contact />}/>
      <Route path='/salsa-for-seniors' element={<SalsaForSeniors />}/>
      <Route path='*' element={<h1>404 Error</h1>}/>

    </Routes>
  );
}

export default App;
