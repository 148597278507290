import '../Styles/slider.css'
import React, { useState, useEffect} from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const Slider = ({sliderJson}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToSlide = (index) => {
        setCurrentIndex(index);
      };
    
    const goToNextSlide = () => {
       const nextIndex = (currentIndex + 1) % sliderJson.length;
       setCurrentIndex(nextIndex);
    };

    const goToPrevSlide = () => {
        const prevIndex = (currentIndex - 1 + sliderJson.length) % sliderJson.length;
        setCurrentIndex(prevIndex);
    };

    useEffect(() => {
        const intervalId = setInterval(goToNextSlide, 5000); // Change the delay as needed (5000 milliseconds = 5 seconds)
    
        return () => {
          clearInterval(intervalId); // Cleanup on component unmount
        };
    }, [currentIndex]);

    const getTransformValue = () => {
        return `translateX(${-currentIndex * 100}%)`;
    };
    
    return (
        <div className="slider-container">
          <div className="slider" style={{ transform: getTransformValue() }}>
            {sliderJson.map((slide, index) => (
              <div key={index} className={`slide ${index === currentIndex ? 'active' : ''}`} >
                <img src={slide.filename} alt={slide.alt} />
              </div>
            ))}
          </div>
          <div className="pagination">
            {sliderJson.map((slide, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
                onClick={() => goToSlide(index)}
              />
            ))}
          </div>
          <button className="prev" onClick={goToPrevSlide}>
            <IoIosArrowBack />
          </button>
          <button className="next" onClick={goToNextSlide}>
            <IoIosArrowForward />
          </button>
        </div>
      );
};

export default Slider;