import '../Styles/gridgallery.css';

const Gallery = ({galleryJson}) => {
    const columnSize = 3;

    return (
        <div className='gallery'>
            {galleryJson.reduce((columns, image, index) => {
                const columnIndex = Math.floor(index / columnSize);

                if (!columns[columnIndex]) {
                    columns[columnIndex] = [];
                }

                columns[columnIndex].push(
                    <div className="gallery_img_wrapper" key={image.index}>
                        <img id={image.id} src={image.filename} alt={`Image ${image.index + 1}`} />
                    </div>
                );

                return columns;
            }, []).map((column, index) => (
                <div key={index} className='gallery_column' id={`column_${index}`}>
                    {column}
                </div>
            ))}
        </div>
    );
};

export default Gallery;