import '../Styles/eventCard.css';
import React, { useState } from 'react';
import { BiSolidLeftArrow } from "react-icons/bi";

const EventCard = ({ eventName, eventInfo, link, videoId }) => {

    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    const openLightbox = () => {
        setIsLightboxOpen(true);
    }

    const closeLightbox = () => {
        setIsLightboxOpen(false);
    }

    return (
        <>
        <div className='eventCard'>
            <div className='event-info'>
                <h2>{eventName}</h2>
                <p>{eventInfo}</p>
            </div>
            <div className='event-prompt-wrapper'>
                {videoId !== '' && (
                    <div className='event-prompt highlights'>
                        <div className='event-tab-wrapper'>< BiSolidLeftArrow /></div>
                        <button className='event-hightlights' onClick={openLightbox}>
                            View<br className='text-break'></br>Highlights
                        </button>
                    </div>
                )}
                <div className='event-prompt link'>
                    <a className="event-link" href={link} target="_blank">
                        <span>Learn<br className='text-break'></br>More</span>
                    </a>
                </div>
            </div>
        </div>

            {isLightboxOpen && (
                <div className='lightbox-overlay' onClick={closeLightbox}>
                    <div className='lightbox-content'>
                        <span className='close-button' onClick={closeLightbox}>
                        &times;
                        </span>
                        <iframe
                        title='YouTube Video'
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder='0'
                        allowfullscreen
                        ></iframe>
                    </div>
                </div>
            )}
        </>
    );

};

export default EventCard;