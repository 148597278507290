import '../Styles/footer.css';
import csfpLogo from '../Media/Logos/csfp-website-footer-csfp.webp';
import tmgLogo from '../Media/Logos/csfp-website-footer-tmg.webp';
import tlnLogo from '../Media/Logos/csfp-website-footer-tln.webp';
import ucLogo from '../Media/Logos/csfp-website-footer-univision.webp';
import hcLogo from '../Media/Logos/csfp-website-footer-hillcrest.webp';
import torontoLogo from '../Media/Logos/csfp-website-footer-toronto.webp';
import ontarioLogo from '../Media/Logos/csfp-website-footer-ontario.webp';
import canadaLogo from '../Media/Logos/csfp-website-footer-canada.webp';
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaSquareYoutube } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";

export default function Footer() {
    return (
        <div className="footer-wrapper">
            <div className='footer-logo-wrapper'>
                <row id="footer-row-1">
                    <a href='https://canadiansalsafestivalsproject.org/' target='_blank'><img id='csfp-logo' src={csfpLogo}></img></a>
                    <a href='https://www.tln.ca/careers/' target='_blank'><img id='tmg-logo' src={tmgLogo}></img></a>
                    <a href='https://www.tln.ca/' target='_blank'><img id='tln-logo' src={tlnLogo}></img></a>
                    <a href='https://univision.ca/' target='_blank'><img id='uc-logo' src={ucLogo}></img></a>
                </row>
                <row id="footer-row-2">
                    <a href='https://www.hillcrestvillagetoronto.com/' target='_blank'><img id='hc-logo' src={hcLogo}></img></a>
                    <a href='https://www.toronto.ca/' target='_blank'><img id='toronto-logo' src={torontoLogo}></img></a>
                    <a href='https://www.ontario.ca/page/government-ontario' target='_blank'><img id='ontario-logo' src={ontarioLogo}></img></a>
                    <a href='https://www.canada.ca/en/canadian-heritage.html' target='_blank'><img id='canada-logo' src={canadaLogo}></img></a>
                </row>
            </div>
            <div className='social-wrapper'>
            <a href='https://www.linkedin.com/company/csfp/' target='_blank'><FaLinkedin /></a>
            <a href='https://www.facebook.com/SalsaInTO' target='_blank'><FaFacebookSquare /></a>
            <a href='https://twitter.com/salsaintoronto' target='_blank'><FaSquareXTwitter /></a>
            <a href='https://www.youtube.com/channel/UCzdBvih4GNtYgZKvv7N95vg' target='_blank'><FaSquareYoutube /></a>
            <a href='https://www.instagram.com/salsaintoronto/' target='_blank'><FaInstagramSquare /></a>
            </div>
            <menu className='footer-nav'>
                <a href="/about" className='nav-item'>About</a>
                <span> | </span>
                <a href="/events" className='nav-item'>Events</a>
                <span> | </span>
                <a href="/community-partners" className='nav-item'>Community Partners</a>
                <span> | </span>
                <a href="/get-involved" className='nav-item'>Get Involved</a>
                <span> | </span>
                <a href="/contact" className='nav-item'>Contact</a>
            </menu>
        </div>
    )
}