import Nav from './Components/Nav';
import Footer from './Components/Footer';
import JobList from './Components/JobList';

export default function Careers () {

    return (
        <>
            <Nav/>
            <section className='page-content'>
                <JobList />
            </section>
            <Footer/>
        </>
    );

};